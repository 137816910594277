import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

export default ({ data }) => {
  const post = data.markdownRemark

  const fullPost = () => {
    return ({
      __html: `<h2>${post.frontmatter.title}</h2>${post.html}`
    })
  }

  return (
    <>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <article dangerouslySetInnerHTML={fullPost()} />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      excerpt
    }
  }
`
